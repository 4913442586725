<template>
  <v-app-bar class="elevation-1 white header" :height="height" ref="header">
    <v-container class="main-header-desktop pt-0 h-100">
      <router-link class="h-100" to="/">
        <img class="logo main-header-desktop-logo" :src="logo" height="100%" />
      </router-link>

      <Top class="ml-auto" />

      <NavMenu class="ml-auto pb-5" :menu="menu" />
    </v-container>
  </v-app-bar>
</template>

<script>
import NavMenu from "./NavMenu.vue";
import Top from "./Top.vue";
import { getWhatsappLinkFromPhone } from "../../../../utils/getWhatsappLinkFromPhone";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    logo() {
      return this.files + this.$rest("informations").item.img;
    },
    whatsappLink() {
      return getWhatsappLinkFromPhone(
        this.$rest("informations").item.whatsapp || ""
      );
    },
    height() {
      return 146;
    },
  },
  components: {
    NavMenu,
    Top,
  },
};
</script>

<style lang="scss" scoped>
.main-header-desktop::v-deep {
  align-items: center;
  display: grid;
  grid-template: "logo . top" "logo . nav" / max-content auto max-content;
  row-gap: 20px;

  :nth-child(1) {
    grid-area: logo;
  }

  :nth-child(2) {
    grid-area: top;
  }

  :nth-child(3) {
    grid-area: nav;
  }

  .main-header-desktop-logo {
    height: 100%;
    margin-bottom: -10px;
    max-width: 300px !important;
    -o-object-fit: contain;
    object-fit: contain;
    padding-top: 10px;

    @media screen and (max-width: 1904px) {
      max-width: 220px !important;
    }
  }
}

.header::v-deep {
  @media screen and (min-width: 960.001px) {
    .v-toolbar__content {
      display: block !important;
      padding: 0 !important;
    }

    .logo {
      width: 100%;
    }
  }
}
</style>
