<template>
  <div :class="['d-flex', alignClass]">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      :class="[
        'text-body-1 text-none text-uppercase font-weight-medium px-14',
        secondary && 'grey--text text--darken-4',
        dark && 'white--text',
      ]"
      :color="dark ? 'white' : !secondary ? 'primary' : 'secondary'"
      height="48px"
      rounded
      depressed
      :type="type"
    >
      <span class="font-secondary">
        <slot />
      </span>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "left",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alignClass() {
      const classes = {
        left: "justify-start",
        center: "justify-center",
        right: "justify-end",
      };

      return classes[this.align];
    },
  },
};
</script>
