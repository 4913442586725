export const mixin = {
  data: () => ({
    fieldProps: {
      outlined: true,
      dense: true,
      flat: true,
      tile: true,
      color: "primary",
    },
  }),
};
