<template>
  <BaseForm
    class="work-with-us-form"
    :class="{ small: small }"
    :action="action"
    :response-to="responseTo"
    gtm-submit-event="form_submit_work-with-us"
  >
    <template #fields>
      <StoreSelect />
      <NameField />
      <EmailField />
      <TelField />
      <FileInput label="Anexar currículo" />
      <PolicyPrivacyField />
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from "./BaseForm.vue";
import StoreSelect from "./fields/StoreSelect.vue";
import NameField from "./fields/NameField.vue";
import EmailField from "./fields/EmailField.vue";
import TelField from "./fields/TelField.vue";
import FileInput from "./fields/FileInput.vue";
import PolicyPrivacyField from "./fields/PolicyPrivacyField.vue";

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    responseTo: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseForm,
    StoreSelect,
    NameField,
    EmailField,
    TelField,
    FileInput,
    PolicyPrivacyField,
  },
};
</script>

<style lang="scss">
.work-with-us-form {
  .base-form-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    @media screen and (min-width: 600.001px) {
      & > :nth-child(5),
      & > :nth-child(6) {
        grid-column: 1 / -1;
      }
    }
  }
}
</style>
