var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('LoadingWidget'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading && _vm.items.length)?_c('div',{staticClass:"position-relative slides slop"},[_c('hooper-container',{ref:"carousel",staticClass:"slides-container",staticStyle:{"height":"auto"},attrs:{"wheelControl":false,"mouseDrag":_vm.items.length > 1,"touchDrag":_vm.items.length > 1,"infiniteScroll":_vm.items.length > 1},on:{"slide":_vm.updateCarousel}},_vm._l((_vm.items),function(ref,keySlide){
var img_desktop = ref.img_desktop;
var img_mobile = ref.img_mobile;
var link = ref.link;
var target = ref.target;
return _c('hooper-slide',{key:keySlide,staticClass:"item"},[_c('v-card',{attrs:{"href":link,"target":target ? '_blank' : '_self',"flat":"","tile":""}},[_c('img',{staticClass:"d-block item-img",attrs:{"src":_vm.files +
                (_vm.xsOnly && img_mobile ? img_mobile : img_desktop) +
                '?resize=1' +
                (_vm.xsOnly ? '&h=600' : '&w=1920'),"width":"100%"}})])],1)}),1),(_vm.items.length > 1)?_c('div',{staticClass:"d-flex justify-space-between slides-nav"},[_c('a',{staticClass:"d-flex align-center justify-center pl-5 slides-nav-item",on:{"click":function($event){$event.preventDefault();return _vm.slidePrev.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"white","size":"12"}},[_vm._v(" fa-angle-left ")])],1),_c('a',{staticClass:"d-flex align-center justify-center pr-5 slides-nav-item",on:{"click":function($event){$event.preventDefault();return _vm.slideNext.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"white","size":"12"}},[_vm._v(" fa-angle-right ")])],1)]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }