import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#ED3237",
        secondary: {
          lighten4: "#F2F3F3",
          lighten3: "#949C9E",
          lighten2: "#61696B",
          lighten1: "#464C4F",
          base: "#303436",
          darken2: "#191B1C",
        },
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    iconfont: "fa",
  },
});
