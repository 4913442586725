const server =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_SERVER
    : location.origin;

const menu = [
  {
    text: "Início",
    to: "/",
  },
  {
    text: "Produtos",
    to: "/produtos",
  },
  {
    text: "Lojas",
    to: "/lojas",
  },
  {
    text: "Marcas Parceiras",
    to: "/marcas-parceiras",
  },
  {
    text: "Galeria",
    to: "/galeria",
  },
  {
    text: "Contato",
    to: "/contato",
  },
  {
    text: "Trabalhe Conosco",
    to: "/trabalhe-conosco",
  },
];

export { server, menu };
