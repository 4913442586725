<template>
  <v-text-field
    v-model="search"
    v-bind="fieldProps"
    type="search"
    label="Buscar"
    append-icon="fas fa-search"
    clear-icon="fas fa-times"
    hide-details
    clearable
  ></v-text-field>
</template>

<script>
import { mixin } from "./mixin";
import { productFilter } from "../../../utils/productFilter";

export default {
  mixins: [mixin, productFilter],
};
</script>
