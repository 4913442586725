<template>
  <div class="d-flex flex-column justify-space-between item">
    <img class="pa-3 item-img" :src="img + '?resize=1&w=300'" />
    <div
      class="
        grey--text
        text-body-2 text-capitalize text-center text--darken-4
        font-weight-bold
        px-2
        mt-3
        mb-4
      "
    >
      {{ title }}
    </div>

    <v-btn color="primary" :to="to" max-height="28px" block tile small>
      VER DETALHES
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item-img {
  aspect-ratio: 1.6;
  border: 1px solid #ddd;
  -o-object-fit: contain;
  object-fit: contain;
}
</style>
