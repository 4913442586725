<template>
  <div>
    <SlideShowSection />
    <ProductsSection />
    <AboutSection />
    <StoreSection />
  </div>
</template>

<script>
import SlideShowSection from "../components/sections/SlideShowSection.vue";
import ProductsSection from "../components/sections/products/Index.vue";
import AboutSection from "../components/sections/AboutSection.vue";
import StoreSection from "../components/sections/StoreSection.vue";

export default {
  components: {
    SlideShowSection,
    ProductsSection,
    AboutSection,
    StoreSection,
  },
};
</script>
