<template>
  <v-card class="d-flex flex-column text-body-2 pa-4" outlined>
    <div class="font-weight-bold">{{ sector }}</div>
    <div>{{ title }}</div>
    <a
      class="text-decoration-none black--text"
      v-if="tel"
      :href="getWhatsappLinkFromPhone(tel)"
      target="_blank"
    >
      <v-icon class="mr-1" color="secondary" small> fab fa-whatsapp </v-icon>

      {{ tel }}
    </a>
    <a
      class="text-decoration-none black--text"
      v-if="email"
      :href="'mailto:' + email"
      target="_blank"
    >
      <v-icon class="mr-1" color="secondary" small> fas fa-envelope </v-icon>

      {{ email }}
    </a>
  </v-card>
</template>

<script>
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    sector: {
      type: String,
      required: true,
    },
    tel: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },
  methods: {
    getWhatsappLinkFromPhone,
  },
};
</script>
