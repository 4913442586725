<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>
      {{ title }}
    </TitleWidget>

    <div class="mt-6 grid-list">
      <ProductFilter />

      <div>
        <LoadingWidget v-if="loading" />

        <template v-else>
          <template v-if="items.length">
            <div class="justify-start items">
              <ProductCard
                v-for="(item, keyItems) in items"
                :key="keyItems"
                v-bind="item"
              ></ProductCard>
            </div>

            <v-pagination
              class="mt-6"
              v-if="items.length && pagesLength"
              v-model="page"
              :length="pagesLength"
              :total-visible="6"
              flat
            ></v-pagination>
          </template>

          <v-alert v-else type="info">
            {{ noContentText }}
          </v-alert>
        </template>
      </div>
    </div>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import ProductFilter from "../components/forms/ProductFilter.vue";
import ProductCard from "../components/cards/ProductCard.vue";
import { formatToURL } from "../utils/formatToURL";
import { productFilter } from "../utils/productFilter";

const _config = {
  mod: "products",
  path: "/produtos",
};

const _pagination = {
  itemsPerPage: 16,
  returnTotalItems: 1,
};

const _data = {
  title: "Produtos",
  loading: true,
  totalItems: 0,
  noContentText: "Nenhum produto adicionado até o momento",
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.getList();
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ id, img, title }) => ({
        img: img ? this.files + "/" + img : "",
        title,
        to: `${_config.path}/${formatToURL(`${title}-${id}`)}`,
      }));
    },
    pagesLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    page: {
      get() {
        const p = this.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.p = v;
        else delete query.p;

        this.$router.push({ path: "/produtos", query });
      },
    },
  },
  mixins: [productFilter],
  methods: {
    getList() {
      this.loading = true;

      const params = {
        returnTotalItems: _pagination.returnTotalItems,
      };

      if (this.page) params.page = this.page;
      if (this.selectedCategory) params.category = this.selectedCategory;
      if (this.selectedSubcategory)
        params.subcategory = this.selectedSubcategory;
      if (this.selectedSubsubcategory)
        params.subsubcategory = this.selectedSubsubcategory;
      if (this.selectedsBrands.length) params.brands = this.selectedsBrands;
      if (this.search) params.search = this.search;

      this.$rest(_config.mod)
        .get({
          params,
          keepCache: true,
          save: (state, data) => {
            if (_pagination.returnTotalItems) {
              this.totalItems = data.totalItems;
              state.list = data.list;
            } else state.list = data;
          },
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.getList();
    },
  },
  components: {
    TitleWidget,
    LoadingWidget,
    ProductFilter,
    ProductCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 80px;
  grid-template-columns: 300px auto;

  @media screen and (max-width: 1264px) {
    gap: 30px;
  }

  @media screen and (max-width: 960px) {
    gap: 0;
    grid-template-columns: auto;
  }
}

.items {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, 330px);

  @media screen and (max-width: 1904px) {
    grid-template-columns: repeat(auto-fill, 246px);
  }

  @media screen and (max-width: 1264px) {
    grid-template-columns: repeat(auto-fill, calc(50% - 10px));
  }

  @media screen and (max-width: 600px) {
    gap: 12px;
    grid-template-columns: repeat(auto-fill, calc(50% - 6px));
  }
}
</style>
