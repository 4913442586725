<template>
  <v-breadcrumbs class="px-0 font-weight-bold" :items="breadcrumbs">
    <template #divider>
      <v-icon x-small>fas fa-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
const _config = {
  path: "/produtos",
};

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    breadcrumbs() {
      const { brand, category, subcategory, subsubcategory } = this.item;

      const r = [
        {
          text: "Início",
          disabled: false,
          to: "/",
        },
      ];

      if (!brand || !brand.id) return r;
      r.push({
        text: brand.title,
        disabled: false,
        to: `${_config.path}?b=brand.id`,
      });

      if (!category || !category.id) return r;
      r.push({
        text: category.title,
        disabled: false,
        to: `${_config.path}?c=${category.id}`,
      });

      if (!subcategory || !subcategory.id) return r;
      r.push({
        text: subcategory.title,
        disabled: false,
        to: `${_config.path}?sc=${subcategory.id}`,
      });

      if (!subsubcategory || !subsubcategory.id) return r;
      r.push({
        text: subsubcategory.title,
        disabled: false,
        to: `${_config.path}?ssc=${subsubcategory.id}`,
      });

      return r;
    },
  },
};
</script>
