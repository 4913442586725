<template>
  <v-select
    :items="items"
    :rules="rules"
    v-bind="fieldProps"
    name="store"
    label="Loja"
  ></v-select>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { mixin } from "./mixin";

const _config = {
  mod: "stores",
};

export default {
  data: () => ({
    rules: [requiredValidation("Selecione uma loja.")],
  }),
  mixins: [mixin],
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    items() {
      return this.$rest(_config.mod)
        .list.filter(({ img, title }) => !!img && !!title)
        .map(({ title }) => ({
          text: title,
          value: title,
        }));
    },
  },
};
</script>
