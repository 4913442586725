var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('div',{staticClass:"pt-10 pt-md-13 pt-lg-16"},[(!_vm.smAndDown)?_c('TitleWidget',[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('CarouselWidget',{staticClass:"mt-8 carousel-store",class:{
      'carousel-store-has-map': _vm.items.filter(function (ref) {
          var map = ref.map;

          return !!map;
}).length,
    },attrs:{"items":_vm.items,"itemsToShow":_vm.itemsToShow,"playSpeed":6000,"spacing":"mt-auto","nav":!_vm.mdAndDown,"dots":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('StoreCard',_vm._b({},'StoreCard',item,false))]}}],null,false,3778615376)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }