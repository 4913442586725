<template>
  <v-select
    :items="items"
    :rules="rules"
    v-bind="fieldProps"
    name="sector"
    label="Setor"
  ></v-select>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { mixin } from "./mixin";

const _config = {
  mod: "staff",
};

export default {
  data: () => ({
    rules: [requiredValidation("Selecione um setor.")],
  }),
  mixins: [mixin],
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    items() {
      return this.$rest(_config.mod)
        .list.filter(({ sector, tel }) => tel && !!sector && sector.title)
        .map(({ sector, title, email }) => ({
          text: sector.title + (title ? ": " + title : ""),
          value: email,
        }));
    },
  },
};
</script>
