<template>
  <div class="py-10 py-md-13 py-lg-16">
    <TitleWidget>
      {{ title }}
    </TitleWidget>

    <Brands />
    <ProductsCategories />

    <ButtonWidget
      class="mt-6 mt-md-10 mt-lg-12"
      :to="moreBtnLink"
      align="center"
    >
      {{ moreBtnText }}
    </ButtonWidget>
  </div>
</template>

<script>
import TitleWidget from "../../widgets/TitleWidget.vue";
import Brands from "./Brands.vue";
import ProductsCategories from "./ProductsCategories.vue";
import ButtonWidget from "../../widgets/ButtonWidget.vue";

const _data = {
  title: "Produtos",
  moreBtnLink: "/produtos",
  moreBtnText: "Ver Todos",
};

export default {
  data: () => ({
    ..._data,
  }),
  components: {
    TitleWidget,
    Brands,
    ProductsCategories,
    ButtonWidget,
  },
};
</script>
