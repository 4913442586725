import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ProductListView from "../views/ProductListView.vue";
import ProductItemView from "../views/productItem/Index.vue";
import StoreView from "../views/StoreView.vue";
import BrandView from "../views/BrandView.vue";
import GalleryListView from "../views/GalleryListView.vue";
import GalleryItemView from "../views/GalleryItemView.vue";
import Error404View from "../views/Error404View.vue";
import PolicyPrivacyView from "../views/PolicyPrivacyView.vue";
import ContactView from "../views/contact/Index.vue";
import ContactResponseView from "../views/ContactResponseView.vue";
import WorkWithUsView from "../views/WorkWithUsView.vue";
import WorkWithUsResponseView from "../views/WorkWithUsResponseView.vue";
// import UnderConstructionView from "../views/UnderConstructionView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Início",
    path: "/",
    component: HomeView,
  },
  {
    name: "Produtos",
    path: "/produtos",
    component: ProductListView,
  },
  {
    name: "Produto",
    path: "/produtos/:item",
    component: ProductItemView,
  },
  {
    name: "Lojas",
    path: "/lojas",
    component: StoreView,
  },
  {
    name: "Marcas Parceiras",
    path: "/marcas-parceiras",
    component: BrandView,
  },
  {
    name: "Galeria",
    path: "/galeria",
    component: GalleryListView,
  },
  {
    name: "Galeria",
    path: "/galeria/:item",
    component: GalleryItemView,
  },
  {
    name: "Contato",
    path: "/contato",
    component: ContactView,
  },
  {
    name: "Obrigado!",
    path: "/contato/obrigado",
    component: ContactResponseView,
  },
  {
    name: "Trabalhe Conosco",
    path: "/trabalhe-conosco",
    component: WorkWithUsView,
  },
  {
    name: "Obrigado!",
    path: "/trabalhe-conosco/obrigado",
    component: WorkWithUsResponseView,
  },
  {
    name: "Política de Privacidade",
    path: "/politica-de-privacidade",
    component: PolicyPrivacyView,
  },
  {
    path: "/erro404",
    name: "Erro 404",
    component: Error404View,
  },
  {
    path: "*",
    name: "Erro 404",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
