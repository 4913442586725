<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>
      {{ title }}
    </TitleWidget>

    <LoadingWidget v-if="loading" />

    <template v-else>
      <div class="mt-8 mt-md-10">
        <div v-if="items.length" class="justify-center grid-list">
          <template v-for="(item, keyItems) in items">
            <BrandCard :key="keyItems" v-bind="item" />
          </template>
        </div>

        <v-alert v-else type="info">
          {{ noContentText }}
        </v-alert>
      </div>
    </template>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import BrandCard from "../components/cards/BrandCard.vue";

const _config = {
  mod: "brands",
  path: "/produtos",
};

const _data = {
  title: "Marcas Parceiras",
  loading: true,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod)
      .get()
      .finally(() => (this.loading = false));
  },
  computed: {
    items() {
      return this.$rest(_config.mod)
        .list.filter(({ img }) => !!img)
        .map(({ id, img, title }) => ({
          img: this.files + img,
          title,
          to: `${_config.path}?b=${id}`,
        }));
    },
  },
  components: {
    TitleWidget,
    LoadingWidget,
    BrandCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(0, 210px));

  @media screen and (max-width: 600px) {
    gap: 12px;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
