<template>
  <div v-if="items.length" class="staff-list">
    <template v-for="(item, keyItems) in items">
      <StaffCard :key="keyItems" v-bind="item" />
    </template>
  </div>
</template>

<script>
import StaffCard from "../../components/cards/StaffCard.vue";

const _config = {
  mod: "staff",
};

export default {
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    items() {
      return this.$rest(_config.mod)
        .list.filter(
          ({ title, sector }) => !!title && !!sector && !!sector.title
        )
        .map(({ title, sector, tel, email }) => ({
          title,
          sector: sector.title,
          tel,
          email,
        }));
    },
  },
  components: {
    StaffCard,
  },
};
</script>

<style lang="scss" scoped>
.staff-list {
  display: grid;
  gap: 20px;
  grid-template-columns: auto auto;

  @media screen and (max-width: 1264px) {
    grid-template-columns: auto;
  }
}
</style>
