var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"d-flex flex-wrap justify-end"},[_vm._l((_vm.menu),function(ref,keyMenu){
var text = ref.text;
var to = ref.to;
return [(text != 'Produtos')?_c('v-btn',{key:text,staticClass:"\n        main-header-btn\n        text-caption text-xl-body-1\n        font-weight-bold\n        px-1\n      ",attrs:{"exact-active-class":"main-header-btn-active","color":"secondary lighten-2","to":to,"text":""}},[_vm._v(" "+_vm._s(text)+" ")]):_c('v-menu',{key:text,attrs:{"offset-y":"","tile":"","max-height":"60vh"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"\n            main-header-btn\n            text-caption text-xl-body-1\n            font-weight-bold\n            px-1\n          ",attrs:{"color":"secondary lighten-2","exact-active-class":"main-header-btn-active","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(text)+" "),_c('v-icon',{staticClass:"ml-3",attrs:{"size":"12"}},[_vm._v("fa-caret-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"tile":"tile"}},_vm._l((_vm.categories),function(ref){
var text = ref.text;
var to = ref.to;
return _c('v-list-item',{key:to,attrs:{"exact":"","to":to}},[_c('v-list-item-title',{staticClass:"grey--text text--darken-3"},[_vm._v(" "+_vm._s(text)+" ")])],1)}),1)],1),(keyMenu + 1 !== _vm.menu.length)?_c('v-divider',{key:keyMenu,staticClass:"ma-3 secondary lighten-3",attrs:{"vertical":""}}):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }