<template>
  <div class="d-flex flex-column flex-md-row">
    <v-sheet width="100%">
      <v-btn
        v-if="smAndDown"
        @click="filter = !filter"
        class="text-body-1 text-none mb-6"
        color="secondary"
        depressed
        block
      >
        <span>Mostrar Filtro</span>
        <v-icon size="14" right>fas fa-filter</v-icon>
      </v-btn>

      <SearchField v-else />

      <v-slide-y-transition v-if="!smAndDown || filter">
        <div>
          <div class="mt-6">
            <TitleWidget x-small align="left"> Marcas </TitleWidget>
            <BrandSelect />
          </div>

          <div class="mt-6">
            <TitleWidget x-small align="left"> Categorias </TitleWidget>
            <ProductCategorySelect />
          </div>

          <div class="mt-6">
            <TitleWidget x-small align="left"> Subcategorias </TitleWidget>

            <ProductSubcategorySelect />
          </div>

          <div class="mt-6">
            <TitleWidget x-small align="left"> Sub-subcategorias </TitleWidget>

            <ProductSubsubcategorySelect />
          </div>

          <v-divider
            class="my-6 my-md-0 mx-md-4"
            :vertical="!smAndDown"
          ></v-divider>
        </div>
      </v-slide-y-transition>
    </v-sheet>
  </div>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import SearchField from "./fields/SearchField.vue";
import BrandSelect from "./fields/BrandSelect.vue";
import ProductCategorySelect from "./fields/ProductCategorySelect.vue";
import ProductSubcategorySelect from "./fields/ProductSubcategorySelect.vue";
import ProductSubsubcategorySelect from "./fields/ProductSubsubcategorySelect.vue";
import { productFilter } from "../../utils/productFilter";

const _data = {
  loading: true,
  filter: false,
};

export default {
  data: () => ({
    ..._data,
  }),
  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  mixins: [productFilter],
  components: {
    TitleWidget,
    SearchField,
    BrandSelect,
    ProductCategorySelect,
    ProductSubcategorySelect,
    ProductSubsubcategorySelect,
  },
};
</script>
