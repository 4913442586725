<template>
  <v-select
    v-model="selectedsBrands"
    :items="items"
    v-bind="fieldProps"
    label="Selecionar marca"
    hide-details
    multiple
  ></v-select>
</template>

<script>
import { mixin } from "./mixin";
import { productFilter } from "../../../utils/productFilter";

export default {
  created() {
    this.$rest("brands").get();
  },
  computed: {
    items() {
      return this.$rest("brands").list.map(({ id, title }) => ({
        text: title,
        value: String(id),
      }));
    },
  },
  mixins: [mixin, productFilter],
};
</script>
