<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <LoadingWidget v-if="loading" />

    <template v-else>
      <TitleWidget>
        {{ item.title }}
      </TitleWidget>

      <div class="d-flex align-center">
        <v-breadcrumbs
          class="mt-4 mt-md-6 pa-0 font-weight-bold"
          :items="breadcrumbs"
        >
          <template #divider>
            <v-icon x-small="x-small">fas fa-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>

      <div class="mt-6 ck-content" v-html="item.text"></div>

      <template v-if="gallery.length">
        <Tinybox v-model="galleryIndex" :images="gallery"></Tinybox>

        <div class="mt-6 grid-list">
          <template v-for="(img, i) in gallery">
            <a :key="i" @click="galleryIndex = i">
              <img class="d-block img-gallery" :src="img" width="100%" />
            </a>
          </template>
        </div>
      </template>
    </template>
  </v-container>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";
import Tinybox from "vue-tinybox";

const _config = {
  mod: "galleries",
};

const _data = {
  loading: true,
  galleryIndex: null,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.getItem();
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    itemId() {
      return this.$route.params.item.replace(/^.*-([0-9]+)$/, "$1");
    },
    breadcrumbs() {
      const { category } = this.item;

      const r = [
        {
          text: "Início",
          exact: true,
          to: "/",
        },
        {
          text: "Galeria",
          exact: true,
          to: "/galeria",
        },
      ];

      if (!category || !category.id) return r;
      r.push({
        text: category.title,
        exact: true,
        to: `/galeria?c=${category.id}`,
      });

      return r;
    },
    gallery() {
      const item = this.item;

      if (item && item.img && item.img.list && item.img.list.length)
        return item.img.list.map(({ path }) => {
          return this.files + path;
        });

      return [];
    },
  },
  methods: {
    getItem() {
      this.loading = true;

      this.$rest(_config.mod)
        .get({
          id: this.itemId,
          save: (state, data) => (state.item = data),
        })
        .catch(() => {
          this.$router.replace("/error404");
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    itemId() {
      this.getItem();
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    Tinybox,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(6, 1fr);

  @media screen and (max-width: 1904px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (max-width: 1264px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .img-gallery {
    aspect-ratio: 4 / 3;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
</style>
