<template>
  <div v-if="item.text" class="grid-list">
    <div>
      <TitleWidget v-if="smAndDown">
        {{ title }}
      </TitleWidget>

      <template v-if="item.video">
        <div
          class="
            mt-4 mt-md-0
            cursor-pointer
            position-relative
            item-video-content
          "
        >
          <div
            v-if="!isPlaying"
            class="d-flex align-center justify-center item-video-mask"
          >
            <v-sheet
              class="
                d-flex
                align-center
                justify-center
                rounded-circle
                pl-1 pl-md-2
                item-video-mask-icon
              "
              color="#ffffff"
              :height="smAndDown ? '40px' : '90px'"
              :width="smAndDown ? '40px' : '90px'"
            >
              <v-icon color="secondary lighten-2" :size="smAndDown ? 20 : 40">
                fa-play
              </v-icon>
            </v-sheet>
          </div>

          <video
            @click="(e) => playVideo(e)"
            ref="video"
            width="100%"
            height="100%"
            :controls="false"
            :src="files + item.video"
          ></video>
        </div>
      </template>

      <template v-else>
        <iframe
          class="d-block"
          :src="`https://www.youtube.com/embed/${getYoutubeCode(item.video)}`"
          width="100%"
          height="100%"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </template>
    </div>

    <v-sheet class="d-flex align-center" color="primary">
      <v-sheet
        class="
          pa-6 pa-lg-10
          pl-lg-16
          white--text
          d-flex
          justify-center
          align-center
        "
        color="transparent"
        max-width="900px"
      >
        <div>
          <TitleWidget v-if="!smAndDown" align="left" dark>
            {{ title }}
          </TitleWidget>

          <div
            class="white--text text-body-2 text-lg-body-1 text-justify mt-3"
            v-html="item.text"
          ></div>
        </div>
      </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import { getYoutubeCode } from "../../utils/getYoutubeCode";

const _config = {
  mod: "about",
};

const _data = {
  title: "Sobre Nós",
  isPlaying: false,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod).get({
      save: (state, data) => (state.item = data),
    });
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    mdOnly() {
      return this.$vuetify.breakpoint.mdOnly;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    getYoutubeCode,
    playVideo(e) {
      const video = e.target;

      if (video.paused) {
        video.play();

        setTimeout(() => {
          this.isPlaying = true;
        }, 100);
      } else {
        video.pause();
        this.isPlaying = false;
      }
    },
  },
  components: {
    TitleWidget,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 0;
  grid-template-columns: 47% 53%;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
}

.item-video-content {
  aspect-ratio: 16 / 9;

  @media screen and (min-width: 960.001px) and (max-width: 1264px) {
    aspect-ratio: 1;
  }

  video,
  iframe {
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .item-video-mask {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.1s linear;

    &-icon {
      opacity: 0.9;
      transition: all 0.1s linear;
    }
  }

  &:hover {
    .item-video-mask {
      background-color: rgba(0, 0, 0, 0.2);

      &-icon {
        opacity: 1;
      }
    }
  }
}
</style>
