<template>
  <v-card class="item" :to="to" flat tile>
    <img class="d-block item-img" :src="img + '?resize=1&w=300'" width="100%" />
    <div
      class="
        grey--text
        text--darken-4 text-body-2 text-center text-capitalize
        font-weight-bold
        pt-3
        px-2
        pb-4
      "
    >
      <span class="font-secondary">{{ title }}</span>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 1.6;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
</style>
