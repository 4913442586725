<template>
  <div
    :class="[
      'd-flex font-weight-bold',
      dark ? 'white--text' : 'secondary--text',
      xSmall ? 'text-body-1' : 'text-h5 text-md-h4',
      className,
    ]"
  >
    <span class="font-secondary">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "center",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
  },
  computed: {
    className() {
      const classNameAlign = {
        left: "text-left justify-start",
        center: "text-center justify-center",
        right: "text-right justify-end",
      };

      return classNameAlign[this.align] || "";
    },
  },
};
</script>
