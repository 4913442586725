<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <LoadingWidget v-if="loading" />

    <template v-else>
      <TitleWidget align="left">
        {{ item.title }}
      </TitleWidget>

      <Breadcrumbs :item="item" />

      <div class="grid-list">
        <Gallery v-if="img && gallery.length" :items="gallery" :main="img" />

        <div>
          <div
            class="text-body-2 text-md-body-1 ck-content"
            v-html="item.text"
          ></div>

          <StaffMember class="mt-6" />
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
import LoadingWidget from "../../components/widgets/LoadingWidget.vue";
import TitleWidget from "../../components/widgets/TitleWidget.vue";
import Breadcrumbs from "./Breadcrumbs.vue";
import Gallery from "./Gallery.vue";
import StaffMember from "./StaffMember.vue";

const _config = {
  mod: "products",
};

const _data = {
  loading: true,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.getItem();
  },
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    img() {
      if (!this.item.img.featured.path) return "";

      return this.files + this.item.img.featured.path;
    },
    gallery() {
      if (!this.item.img.list) return [];

      return this.item.img.list.map(({ path }) => {
        return this.files + path;
      });
    },
    itemId() {
      return this.$route.params.item.replace(/^.*-([0-9]+)$/, "$1");
    },
  },
  methods: {
    getItem() {
      this.loading = true;

      this.$rest(_config.mod)
        .get({ id: this.itemId, save: (state, data) => (state.item = data) })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.getItem();
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    Breadcrumbs,
    Gallery,
    StaffMember,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 20px;
  grid-template-columns: 608px auto;

  @media screen and (max-width: 1264px) {
    grid-template-columns: auto;
  }
}
</style>
