<template>
  <TooltipWidget :tip="title" top>
    <v-card
      v-on="$listeners"
      class="px-2 px-xl-4 py-4 py-xl-6 item"
      :class="{ 'item-hoverable': hoverable }"
      :to="to"
      flat
      tile
    >
      <img
        class="d-block item-img"
        :src="img + '?resize=1&w=200'"
        :alt="title"
      />
    </v-card>
  </TooltipWidget>
</template>

<script>
import TooltipWidget from "../widgets/TooltipWidget.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    to: {
      type: String,
    },
    hoverable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TooltipWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  aspect-ratio: 16 / 9;

  &.item-hoverable {
    filter: grayscale(1);
    transition: 0.2s;

    &:hover {
      filter: grayscale(0);
    }
  }

  &-img {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%;
  }
}
</style>
