<template>
  <div>
    <v-select
      v-if="items.length"
      v-model="selectedSubcategory"
      :items="items"
      v-bind="fieldProps"
      label="Selecionar subcategoria"
      clear-icon="fas fa-times"
      hide-details
      clearable
    ></v-select>

    <div v-else-if="!selectedCategory">Nenhuma categoria selecionada.</div>

    <div v-else>Nenhuma subcategoria disponível.</div>
  </div>
</template>

<script>
import { mixin } from "./mixin";
import { productFilter } from "../../../utils/productFilter";

export default {
  created() {
    this.$rest("productsSubcategories").get();
  },
  computed: {
    items() {
      if (!this.selectedCategory) return [];

      return this.$rest("productsSubcategories")
        .list.filter(({ category }) => category.id == this.selectedCategory)
        .map(({ id, title }) => ({
          text: title,
          value: String(id),
        }));
    },
  },
  mixins: [mixin, productFilter],
};
</script>
