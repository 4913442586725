<template>
  <div v-if="items.length" class="pt-10 pt-md-13 pt-lg-16">
    <TitleWidget v-if="!smAndDown">
      {{ title }}
    </TitleWidget>

    <CarouselWidget
      class="mt-8 carousel-store"
      :class="{
        'carousel-store-has-map': items.filter(({ map }) => !!map).length,
      }"
      :items="items"
      :itemsToShow="itemsToShow"
      :playSpeed="6000"
      spacing="mt-auto"
      :nav="!mdAndDown"
      dots
    >
      <template #item="{ item }">
        <StoreCard v-bind="item" />
      </template>
    </CarouselWidget>
  </div>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import CarouselWidget from "../widgets/CarouselWidget.vue";
import StoreCard from "../cards/StoreCard.vue";
import { getContact } from "../../utils/getContact";

const _config = {
  mod: "stores",
};

const _data = {
  title: "Lojas",
};

const _carouselSettings = {
  itemsToShow: {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1,
  },
};

export default {
  data: () => ({
    ..._data,
    ..._carouselSettings,
  }),
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    items() {
      return this.$rest(_config.mod)
        .list.filter(({ img, title }) => !!img && !!title)
        .map(
          ({
            img,
            title,
            address,
            google_maps,
            tel,
            whatsapp,
            email,
            map,
            text,
          }) => ({
            img: this.files + img,
            title,
            contacts: getContact({
              address,
              google_maps,
              tel,
              whatsapp,
              email,
            }),
            text,
            map,
          })
        );
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  components: {
    TitleWidget,
    CarouselWidget,
    StoreCard,
  },
};
</script>

<style lang="scss" scoped>
.carousel-store-has-map::v-deep {
  .carousel-pagination {
    left: 0;
    margin-top: -395px !important;
    right: 0;
    position: absolute;
    top: 100%;

    @media screen and (max-width: 960px) {
      margin-top: -380px !important;
    }
  }

  .carousel-slides-nav {
    left: 30px;
    right: 30px;
    margin-top: -215px !important;

    @media screen and (max-width: 1264px) {
      left: 5px;
      right: 5px;
    }

    @media screen and (max-width: 960px) {
      margin-top: -215px !important;
    }
  }
}
</style>
