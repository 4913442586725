<template>
  <div>
    <v-select
      v-if="items.length"
      v-model="selectedSubsubcategory"
      :items="items"
      v-bind="fieldProps"
      label="Selecionar subcategoria"
      clear-icon="fas fa-times"
      hide-details
      clearable
    ></v-select>

    <div v-else-if="!selectedSubcategory">
      Nenhuma subcategoria selecionada.
    </div>
    <div v-else>Nenhuma sub-subcategoria disponível.</div>
  </div>
</template>

<script>
import { mixin } from "./mixin";
import { productFilter } from "../../../utils/productFilter";

export default {
  created() {
    this.$rest("productsSubsubcategories").get();
  },
  computed: {
    items() {
      if (!this.selectedSubcategory) return [];

      return this.$rest("productsSubsubcategories")
        .list.filter(({ category }) => category.id == this.selectedSubcategory)
        .map(({ id, title }) => ({
          text: title,
          value: String(id),
        }));
    },
  },
  mixins: [mixin, productFilter],
};
</script>
