<template>
  <footer class="shell-footer">
    <v-sheet color="secondary darken-2" class="position-relative">
      <v-container class="pt-6 pt-md-8 pb-6">
        <div class="justify-center justify-sm-space-between grid-list">
          <div class="grid-list-item">
            <router-link to="/">
              <img
                v-if="informations.img"
                :src="files + informations.img"
                height="65px"
                class="d-block mx-auto mx-md-0"
              />
            </router-link>
          </div>

          <div class="grid-list-item">
            <div class="d-flex justify-center justify-sm-end align-center">
              <div
                v-for="({ text, link, icon, size }, keySocial) in social"
                :key="keySocial"
                class="d-inline-flex"
              >
                <v-btn
                  class="ml-2"
                  :class="{ 'ml-2': !xsOnly && keySocial > 0, 'mx-1': xsOnly }"
                  :href="link"
                  target="_blank"
                  min-width="auto"
                  color="secondary lighten-3"
                  small
                  :title="text"
                  depressed
                  rounded
                  fab
                >
                  <v-icon color="secondary" :size="size">{{ icon }}</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </v-sheet>

    <v-sheet color="secondary darken-2">
      <v-container class="pa-0">
        <v-sheet
          color="rgba(255, 255, 255, 0.10)"
          width="100%"
          height="1px"
        ></v-sheet>
      </v-container>
      <v-container
        class="
          pt-0
          pb-5
          d-flex
          flex-column flex-sm-row
          justify-space-between
          align-center
          text-center
        "
      >
        <div class="secondary--text text--lighten-3 text-body-2 mt-5">
          Copyright ©
          {{ year }} {{ informations.name }}.
          <span class="d-block d-sm-inline-block">
            Todos os direitos reservados.
          </span>
        </div>
        <a
          class="d-flex mt-5"
          href="https://www.mrxweb.com.br/"
          target="_blank"
        >
          <img
            class="shell-footer-logo"
            src="../../assets/img/mrxweb.svg"
            alt="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            title="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            height="20px"
          />
        </a>
      </v-container>
    </v-sheet>
  </footer>
</template>

<script>
import { getSocial } from "../../utils/getSocial";

export default {
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-footer {
  .grid-list {
    display: grid;
    gap: 24px;
    grid-template-columns: auto auto;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .btn-contact::v-deep {
      .v-btn__content {
        max-width: 100%;
        white-space: normal;
      }
    }
  }
}
</style>
