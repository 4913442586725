<template>
  <div class="px-4 item" :class="{ 'item-opposite': !smAndDown && opposite }">
    <v-sheet class="mx-auto" color="transparent" max-width="1000px">
      <div class="align-center justify-center item-grid-list">
        <div
          class="d-flex flex-column item-content"
          :class="{ 'pr-md-6': !opposite, 'pl-md-6': opposite }"
        >
          <TitleWidget :align="smAndDown ? 'center' : 'left'" small>
            {{ title }}
          </TitleWidget>

          <template v-if="contacts.length">
            <div class="mx-n4 mt-3">
              <div v-for="(item, keyContact) in contacts" :key="keyContact">
                <v-btn
                  v-if="!Array.isArray(item)"
                  class="
                    secondary--text
                    text--lighten-2 text-body-2 text-none
                    btn-contact
                  "
                  height="auto"
                  min-height="36px"
                  :href="item.link"
                  :target="item.target"
                  :title="item.name"
                  depressed
                  color="transparent"
                >
                  <v-icon
                    v-if="item.icon"
                    class="mr-2"
                    color="primary"
                    :size="item.size"
                  >
                    {{ item.icon }}
                  </v-icon>

                  <span v-html="item.text"></span>
                </v-btn>

                <div v-else class="d-flex align-center">
                  <div
                    class="d-inline-flex align-center justify-start"
                    v-for="(
                      { name, text, link, target, icon, size }, keyContactItem
                    ) in item"
                    :key="keyContactItem"
                  >
                    <v-sheet
                      v-if="keyContactItem > 0"
                      color="secondary lighten-4"
                      width="1px"
                      height="26px"
                    ></v-sheet>

                    <v-btn
                      class="
                        secondary--text
                        text--lighten-2 text-body-2 text-none
                        btn-contact
                      "
                      height="auto"
                      min-height="36px"
                      :href="link"
                      :target="target"
                      :title="name"
                      depressed
                      color="transparent"
                    >
                      <v-icon
                        v-if="icon"
                        class="mr-2"
                        color="primary"
                        :size="size"
                      >
                        {{ icon }}
                      </v-icon>

                      <span v-html="text"></span>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div
            class="
              text--secondary text--lighten-2 text-body-1
              mt-6 mt-md-8
              ck-content
            "
            v-html="text"
          ></div>
        </div>

        <img
          class="rounded-lg item-img"
          :src="img + '?resize=1&w=600'"
          width="100%"
          height="100%"
        />
      </div>
    </v-sheet>

    <template v-if="map">
      <v-sheet
        class="mx-n4 item-map"
        color="secondary lighten-3"
        v-html="map"
      ></v-sheet>
    </template>
  </div>
</template>

<script>
import TitleWidget from "../widgets/TitleWidget.vue";
import { getTelLinkFromPhone } from "../../utils/getTelLinkFromPhone";
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";

export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    map: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    opposite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      const items = [];

      if (this.tel)
        items.push({
          link: getTelLinkFromPhone(this.tel),
          icon: "fas fa-phone",
          text: this.tel,
        });

      if (this.whatsapp)
        items.push({
          link: getWhatsappLinkFromPhone(this.whatsapp),
          icon: "fab fa-whatsapp",
          text: this.whatsapp,
        });

      if (this.local)
        items.push({
          link: this.localLink,
          icon: "fas fa-map-marker-alt",
          text: this.local,
        });

      if (this.email)
        items.push({
          link: `mailto:${this.email}`,
          icon: "fas fa-envelope",
          text: this.email,
        });

      return items;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    getTelLinkFromPhone,
    getWhatsappLinkFromPhone,
  },
  components: {
    TitleWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-grid-list {
    display: grid;
    gap: 0;
    grid-template-columns: 45% 55%;

    @media screen and (max-width: 960px) {
      gap: 20px;
      grid-template-columns: minmax(0, 600px);
    }
  }

  &-img {
    aspect-ratio: 4 / 3;
    -o-object-fit: cover;
    object-fit: cover;

    @media screen and (max-width: 960px) {
      aspect-ratio: 16 / 9;
    }
  }

  &-map::v-deep {
    height: 350px;
    margin-top: 80px;

    @media screen and (max-width: 960px) {
      margin-top: 60px;
    }

    iframe {
      display: block;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
    }
  }

  &.item-opposite {
    .item-grid-list {
      grid-template-columns: 55% 45%;
    }

    .item-img {
      order: 0;
    }

    .item-content {
      order: 12;
    }
  }
}

.btn-contact::v-deep {
  .v-btn__content {
    max-width: 100%;
    white-space: normal;
  }
}
</style>
