export const productFilter = {
  computed: {
    query() {
      return this.$route.query;
    },
    search: {
      get() {
        return this.query.s || "";
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.s = v;
        else delete query.s;

        delete query.p;

        this.$router.push({ path: "/produtos", query });
      },
    },
    selectedsBrands: {
      get() {
        const b = this.query.b;
        return b ? b.split(",") : [];
      },
      set(v) {
        const query = { ...this.query };
        if (v.length) query.b = v.join();
        else delete query.b;

        delete query.p;

        this.$router.push({ path: "/produtos", query });
      },
    },
    selectedCategory: {
      get() {
        return this.query.c;
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.c = v;
        else delete query.c;

        delete query.p;
        delete query.sc;
        delete query.ssc;

        this.$router.push({ path: "/produtos", query });
      },
    },
    selectedSubcategory: {
      get() {
        return this.query.sc;
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.sc = v;
        else delete query.sc;

        delete query.ssc;
        delete query.p;

        this.$router.push({ path: "/produtos", query });
      },
    },
    selectedSubsubcategory: {
      get() {
        return this.query.ssc;
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.ssc = v;
        else delete query.ssc;

        delete query.p;

        this.$router.push({ path: "/produtos", query });
      },
    },
  },
};
