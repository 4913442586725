<template>
  <v-sheet
    class="px-3 py-10 py-md-13 py-lg-16 mx-auto"
    color="transparent"
    max-width="500px"
  >
    <TitleWidget>{{ title }}</TitleWidget>

    <WorkWithUsForm
      class="mt-6 mt-md-8"
      :action="server + formAction"
      :response-to="responseTo"
    />
  </v-sheet>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import WorkWithUsForm from "../components/forms/WorkWithUsForm.vue";

const _config = {
  formAction: "/rest/email/work-with-us",
  responseTo: "/trabalhe-conosco/obrigado",
};

const _data = {
  title: "Trabalhe Conosco",
};

export default {
  data: () => ({
    ..._config,
    ..._data,
  }),
  components: {
    TitleWidget,
    WorkWithUsForm,
  },
};
</script>
