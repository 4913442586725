<template>
  <v-container class="py-0">
    <div v-if="items.length" class="mx-n1 mx-sm-n2">
      <CarouselWidget
        class="mt-6 page-home-products"
        :items="items"
        :itemsToShow="itemsToShow"
        spacing="px-1 px-sm-2"
        :infiniteScroll="true"
        :nav="!smAndDown"
        :dots="smAndDown"
      >
        <template #item="{ item }">
          <BrandCard v-bind="item" hoverable />
        </template>
      </CarouselWidget>
    </div>
  </v-container>
</template>

<script>
import CarouselWidget from "../../widgets/CarouselWidget.vue";
import BrandCard from "../../cards/BrandCard.vue";

const _config = {
  mod: "brands",
  path: "/produtos",
};

const _carouselSettings = {
  itemsToShow: {
    xs: 2,
    sm: 3,
    md: 5,
    lg: 7,
    xl: 8,
  },
};

export default {
  data: () => ({
    ..._carouselSettings,
  }),
  created() {
    this.$rest(_config.mod).get();
  },
  computed: {
    items() {
      return this.$rest(_config.mod)
        .list.filter(({ img }) => !!img)
        .map(({ id, img, title }) => ({
          img: this.files + img,
          title,
          to: `${_config.path}?b=${id}`,
        }));
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  components: {
    CarouselWidget,
    BrandCard,
  },
};
</script>
