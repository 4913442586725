<template>
  <v-card
    v-if="item && item.whatsapp"
    class="d-flex justify-center"
    :href="getWhatsappLinkFromPhone(item.whatsapp)"
    target="_blank"
    width="max-content"
    flat
  >
    <v-sheet class="d-flex align-center pa-5 rounded-0" color="#25D366">
      <v-icon color="white" large>fab fa-whatsapp</v-icon>
    </v-sheet>

    <div class="secondary white--text rounded-0 py-1 px-4">
      <div class="text-h6 text-md-h5 font-weight-bold">
        Solicite um orçamento
      </div>

      <div class="text-h6">
        {{ item.whatsapp }}
      </div>
    </div>
  </v-card>
</template>

<script>
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";

const _config = {
  mod: "informations",
};

export default {
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
  },
  methods: {
    getWhatsappLinkFromPhone,
  },
};
</script>
