var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 item",class:{ 'item-opposite': !_vm.smAndDown && _vm.opposite }},[_c('v-sheet',{staticClass:"mx-auto",attrs:{"color":"transparent","max-width":"1000px"}},[_c('div',{staticClass:"align-center justify-center item-grid-list"},[_c('div',{staticClass:"d-flex flex-column item-content",class:{ 'pr-md-6': !_vm.opposite, 'pl-md-6': _vm.opposite }},[_c('TitleWidget',{attrs:{"align":_vm.smAndDown ? 'center' : 'left',"small":""}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.contacts.length)?[_c('div',{staticClass:"mx-n4 mt-3"},_vm._l((_vm.contacts),function(item,keyContact){return _c('div',{key:keyContact},[(!Array.isArray(item))?_c('v-btn',{staticClass:"\n                  secondary--text\n                  text--lighten-2 text-body-2 text-none\n                  btn-contact\n                ",attrs:{"height":"auto","min-height":"36px","href":item.link,"target":item.target,"title":item.name,"depressed":"","color":"transparent"}},[(item.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":item.size}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})],1):_c('div',{staticClass:"d-flex align-center"},_vm._l((item),function(ref,keyContactItem){
var name = ref.name;
var text = ref.text;
var link = ref.link;
var target = ref.target;
var icon = ref.icon;
var size = ref.size;
return _c('div',{key:keyContactItem,staticClass:"d-inline-flex align-center justify-start"},[(keyContactItem > 0)?_c('v-sheet',{attrs:{"color":"secondary lighten-4","width":"1px","height":"26px"}}):_vm._e(),_c('v-btn',{staticClass:"\n                      secondary--text\n                      text--lighten-2 text-body-2 text-none\n                      btn-contact\n                    ",attrs:{"height":"auto","min-height":"36px","href":link,"target":target,"title":name,"depressed":"","color":"transparent"}},[(icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":size}},[_vm._v(" "+_vm._s(icon)+" ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(text)}})],1)],1)}),0)],1)}),0)]:_vm._e(),_c('div',{staticClass:"\n            text--secondary text--lighten-2 text-body-1\n            mt-6 mt-md-8\n            ck-content\n          ",domProps:{"innerHTML":_vm._s(_vm.text)}})],2),_c('img',{staticClass:"rounded-lg item-img",attrs:{"src":_vm.img + '?resize=1&w=600',"width":"100%","height":"100%"}})])]),(_vm.map)?[_c('v-sheet',{staticClass:"mx-n4 item-map",attrs:{"color":"secondary lighten-3"},domProps:{"innerHTML":_vm._s(_vm.map)}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }