<template>
  <div
    v-if="items.length"
    class="
      px-1 px-sm-3
      pl-md-8 pl-lg-16
      mt-10 mt-md-13 mt-pt-16
      overflow-hidden
    "
  >
    <div class="mx-n1 mx-sm-n3">
      <CarouselWidget
        class="products-categories"
        :items="items"
        :itemsToShow="itemsToShow"
        spacing="px-1 px-sm-3"
        :playSpeed="4000"
        :nav="!xsOnly"
        :dots="xsOnly"
        infiniteScroll
      >
        <template #item="{ item }">
          <ProductCategoryCard v-bind="item" />
        </template>
      </CarouselWidget>
    </div>
  </div>
</template>

<script>
import CarouselWidget from "../../widgets/CarouselWidget.vue";
import ProductCategoryCard from "../../cards/ProductCategoryCard.vue";

const _config = {
  mod: "productsCategories",
  path: "/produtos",
};

const _carouselSettings = {
  itemsToShow: {
    xs: 2,
    sm: 3,
    md: 3.5,
    lg: 4.5,
    xl: 6.5,
  },
};

export default {
  data: () => ({
    ..._carouselSettings,
  }),
  created() {
    const params = {
      featured: 1,
    };

    this.$rest(_config.mod).get({ params });
  },
  computed: {
    items() {
      return this.$rest(_config.mod)
        .list.filter(({ img }) => !!img)
        .map(({ id, img, title }) => ({
          img: this.files + img,
          title,
          to: `${_config.path}?c=${id}`,
        }));
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    CarouselWidget,
    ProductCategoryCard,
  },
};
</script>

<style lang="scss" scoped>
.products-categories::v-deep {
  @media screen and (min-width: 960.001px) {
    padding-right: 0 !important;
  }

  .carousel-slides-nav {
    height: 100%;
    left: -52px;
    margin-top: 0;
    padding: 0 !important;
    pointer-events: none;
    right: 0;
    top: 0;

    @media screen and (max-width: 1264px) {
      left: -20px;
    }

    @media screen and (max-width: 960px) {
      left: 0;
    }

    &-item {
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 0 24px 24px 0;
      height: 100%;
      padding: 0 !important;
      pointer-events: initial;
      width: 70px;

      @media screen and (max-width: 960px) {
        width: 40px;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.6);
      }

      &:last-child {
        border-radius: 24px 0 0 24px;

        @media screen and (min-width: 1904.001px) {
          width: 130px;
        }

        @media screen and (min-width: 1264.001px) and (max-width: 1904px) {
          width: calc(calc(100vw - 170px) / 4.5 / 2);
        }

        @media screen and (min-width: 960.001px) and (max-width: 1264px) {
          width: calc(calc(100vw - 160px) / 3.5 / 2);
        }
      }
    }
  }
}
</style>
