<template>
  <div class="py-10 py-md-13 py-lg-16">
    <TitleWidget v-if="(smAndDown && !items.length) || !smAndDown">
      {{ title }}
    </TitleWidget>

    <LoadingWidget v-if="loading" />

    <template v-else>
      <div class="mt-md-10">
        <div v-if="items.length">
          <template v-for="(item, keyItems) in items">
            <StoreCard
              :class="{ 'mt-10 mt-md-13 mt-lg-16': keyItems > 0 }"
              :key="keyItems"
              v-bind="item"
              :opposite="keyItems % 2 !== 0"
            />
          </template>
        </div>

        <v-alert v-else type="info">
          {{ noContentText }}
        </v-alert>
      </div>
    </template>
  </div>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import StoreCard from "../components/cards/StoreCard.vue";
import { getContact } from "../utils/getContact";

const _config = {
  mod: "stores",
};

const _data = {
  title: "Lojas",
  loading: true,
};

export default {
  data: () => ({
    ..._data,
  }),
  created() {
    this.$rest(_config.mod)
      .get()
      .finally(() => (this.loading = false));
  },
  computed: {
    items() {
      return this.$rest(_config.mod)
        .list.filter(({ img, title }) => !!img && !!title)
        .map(
          ({
            img,
            title,
            address,
            google_maps,
            tel,
            whatsapp,
            email,
            text,
          }) => ({
            img: this.files + img,
            title,
            contacts: getContact({
              address,
              google_maps,
              tel,
              whatsapp,
              email,
            }),
            text,
          })
        );
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  components: {
    TitleWidget,
    LoadingWidget,
    StoreCard,
  },
};
</script>
