<template>
  <nav class="d-flex flex-wrap justify-end">
    <template v-for="({ text, to }, keyMenu) in menu">
      <v-btn
        v-if="text != 'Produtos'"
        :key="text"
        class="
          main-header-btn
          text-caption text-xl-body-1
          font-weight-bold
          px-1
        "
        exact-active-class="main-header-btn-active"
        color="secondary lighten-2"
        :to="to"
        text
      >
        {{ text }}
      </v-btn>
      <v-menu v-else :key="text" offset-y tile max-height="60vh">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="
              main-header-btn
              text-caption text-xl-body-1
              font-weight-bold
              px-1
            "
            v-bind="attrs"
            v-on="on"
            color="secondary lighten-2"
            exact-active-class="main-header-btn-active"
            text
          >
            {{ text }}
            <v-icon class="ml-3" size="12">fa-caret-down</v-icon>
          </v-btn>
        </template>
        <v-list tile="tile">
          <v-list-item
            v-for="{ text, to } in categories"
            exact
            :key="to"
            :to="to"
          >
            <v-list-item-title class="grey--text text--darken-3">
              {{ text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-divider
        :key="keyMenu"
        v-if="keyMenu + 1 !== menu.length"
        class="ma-3 secondary lighten-3"
        vertical
      ></v-divider>
    </template>
  </nav>
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.$rest("products-categories").get();
  },
  computed: {
    categories() {
      return this.$rest("products-categories").list.map(({ id, title }) => ({
        text: title,
        to: "/produtos?c=" + id,
      }));
    },
  },
};
</script>

<style>
.main-header-btn {
  position: relative;
}
.main-header-btn-active::before {
  background-color: transparent !important;
}
.main-header-btn::after {
  content: "";
  display: block;
  background: var(--v-primary-base);
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0%;
  transition: 0.5s;
  margin: 0px 5px;
}
.main-header-btn-active::after {
  left: 0px;
  width: calc(100% - 10px);
}
</style>
