<template>
  <div
    class="
      py-4
      pl-10
      pr-0
      primary
      d-flex
      align-center
      position-relative
      main-header-top
    "
  >
    <v-text-field
      v-model="search"
      class="rounded-0"
      :class="{ 'mr-8': informations.tel || social.length }"
      append-icon="fas fa-search"
      label="Buscar"
      hide-details
      dense
      solo
      flat
    ></v-text-field>

    <template v-if="social.length">
      <TooltipWidget
        class="ml-2 d-block"
        v-for="({ text, link, icon }, i) in social"
        :key="i"
        :tip="text"
        bottom
      >
        <v-btn
          class="mb-1"
          :href="link"
          color="white"
          target="_blank"
          depressed
          x-small
          fab
        >
          <v-icon color="primary">{{ icon }}</v-icon>
        </v-btn>
      </TooltipWidget>
    </template>
  </div>
</template>

<script>
import TooltipWidget from "../../../widgets/TooltipWidget.vue";
import { getSocial } from "../../../../utils/getSocial";
import { getTelLinkFromPhone } from "../../../../utils/getTelLinkFromPhone";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
    query() {
      return this.$route.query;
    },
    search: {
      get() {
        return this.query.s || "";
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.s = v;
        else delete query.s;

        delete query.p;

        this.$router.push({ path: "/produtos", query });
      },
    },
  },
  methods: {
    getTelLinkFromPhone,
  },
  components: {
    TooltipWidget,
  },
};
</script>

<style lang="scss" scoped>
.main-header-top {
  @media screen and (min-width: 1264.001px) {
    width: 100%;
  }

  &:before {
    border-top: 70px solid transparent;
    border-bottom: 0 solid transparent;
    border-right: 30px solid var(--v-primary-base);
    content: "";
    height: 0;
    margin-left: -1px;
    position: absolute;
    right: 100%;
    top: 0;
    width: 0;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    background: var(--v-primary-base);
    top: 0;
    height: 100%;
    right: calc((100vw - var(--container-width) + 30px) / 2 * -1);
    width: calc((100vw - var(--container-width) + 32px) / 2);
    z-index: -1;

    @media (min-width: 960px) {
      --container-width: 900px;
    }

    @media (min-width: 1264px) {
      --container-width: 1185px;
    }

    @media (min-width: 1904px) {
      --container-width: 1785px;
    }
  }
}
</style>
