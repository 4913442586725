<template>
  <v-file-input
    :rules="rules"
    name="attachment"
    :label="label"
    v-bind="fieldProps"
    background-color="white"
    accept=".doc,.docx,application/msword,application/pdf"
  ></v-file-input>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";
import { mixin } from "./mixin";

export default {
  props: {
    label: {
      type: String,
      default: "Anexar arquivo",
    },
  },
  data: () => ({
    rules: [requiredValidation("Selecione seu currículo.")],
  }),
  mixins: [mixin],
};
</script>
