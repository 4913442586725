<template>
  <v-container class="py-10 py-md-13 py-lg-16 px-1 px-sm-3">
    <TitleWidget>
      {{ title }}
    </TitleWidget>

    <div class="d-flex justify-center pb-4" v-if="categories.length">
      <v-chip-group
        v-model="categoriesSelecteds"
        active-class="primary white--text"
        column
        multiple
        center
      >
        <v-chip
          class="rounded-pill py-4 text-body-2"
          v-for="({ text, value }, i) in categories"
          :value="value"
          :key="i"
        >
          <span class="font-weight-medium">{{ text }}</span>
        </v-chip>
      </v-chip-group>
    </div>

    <LoadingWidget v-if="loading" />

    <template v-else>
      <template v-if="items.length">
        <v-row class="mt-6 mx-n1 mx-sm-n3">
          <template v-for="(item, keyItems) in items">
            <v-col
              :key="keyItems"
              class="px-1 px-sm-3"
              cols="6"
              md="4"
              lg="3"
              xl="2"
            >
              <GalleryCard v-bind="item"></GalleryCard>
            </v-col>
          </template>
        </v-row>

        <v-pagination
          v-if="items.length && pagesLength"
          class="mt-6"
          v-model="page"
          :length="pagesLength"
          :total-visible="pagesTotalVisible"
          flat
        ></v-pagination>
      </template>

      <div class="d-flex justify-center mt-6" v-else>
        <v-alert class="mx-auto" type="info" color="secondary">
          Nenhuma galeria encontrada.
        </v-alert>
      </div>
    </template>
  </v-container>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import GalleryCard from "../components/cards/GalleryCard.vue";
import { formatToURL } from "../utils/formatToURL";

const _config = {
  mod: "galleries",
};

const _pagination = {
  itemsPerPage: 16,
  returnTotalItems: 1,
};

const _data = {
  loading: true,
  title: "Galerias",
  pagesTotalVisible: 6,
  totalItems: 0,
};

export default {
  data: () => ({
    ..._data,
  }),
  beforeCreate() {
    this.$rest("categories").get();
  },
  created() {
    this.getList();
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list.map(({ id, img, title }) => ({
        img: img ? this.files + img : "",
        title,
        to: `/galeria/${formatToURL(`${title}-${id}`)}`,
      }));
    },
    categories() {
      return this.$rest("categories")
        .list.filter(({ modules_fields_id }) => modules_fields_id === 57)
        .map(({ id, title }) => ({
          text: title,
          value: id,
        }));
    },
    query() {
      return this.$route.query;
    },
    pagesLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    page: {
      get() {
        const p = this.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.p = v;
        else delete query.p;

        this.$router.push({ path: "/eventos", query });
      },
    },
    categoriesSelecteds: {
      get() {
        return this.query.c
          ? this.query.c.split(",").map((v) => parseInt(v))
          : [];
      },
      set(v) {
        const query = { ...this.query };
        if (v) query.c = v.join(",");
        else delete query.c;

        this.$router.push({ query });
      },
    },
    sm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        returnTotalItems: _pagination.returnTotalItems,
        itemsPerPage: _pagination.itemsPerPage,
      };

      if (this.page) params.page = this.page;
      if (this.query.c) params.category = this.query.c;

      this.$rest(_config.mod)
        .get({
          params,
          keepCache: true,
          save: (state, data) => {
            if (_pagination.returnTotalItems) {
              this.totalItems = data.totalItems;
              state.list = data.list;
            } else state.list = data;
          },
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.getList();
    },
  },
  components: {
    TitleWidget,
    LoadingWidget,
    GalleryCard,
  },
};
</script>
