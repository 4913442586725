<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>{{ title }}</TitleWidget>

    <div class="justify-content-center mt-6 mt-md-8 grid-list">
      <ContactForm
        class="ml-0 ml-sm-auto"
        :action="server + formAction"
        :response-to="responseTo"
      ></ContactForm>

      <Staff />
    </div>
  </v-container>
</template>

<script>
import TitleWidget from "../../components/widgets/TitleWidget.vue";
import ContactForm from "../../components/forms/ContactForm.vue";
import Staff from "./Staff.vue";

const _data = {
  title: "Contato",
};

const _config = {
  formAction: "/rest/email/contact-us",
  responseTo: "/contato/obrigado",
};

export default {
  data: () => ({
    ..._data,
    ..._config,
  }),
  components: {
    TitleWidget,
    ContactForm,
    Staff,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 600px) {
    grid-template-columns: auto;
  }
}
</style>
