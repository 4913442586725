<template>
  <div>
    <v-app-bar
      class="header-mobile"
      scroll-threshold="100"
      elevate-on-scroll
      hide-on-scroll
      color="primary"
      app
    >
      <router-link to="/">
        <img
          v-if="informations.img"
          :src="files + informations.img"
          :alt="informations.name"
          height="46px"
          class="d-block filter-white"
        />
      </router-link>

      <v-spacer />

      <template v-for="(item, keySocial) in social">
        <v-btn
          v-if="item"
          :key="keySocial"
          :href="item.link"
          target="blank"
          class="mr-1 d-flex"
          width="40px"
          height="40px"
          icon
        >
          <v-icon color="white" size="20">
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </template>
      <v-app-bar-nav-icon color="white" @click="drawer = !drawer" />
    </v-app-bar>

    <v-navigation-drawer
      class="header-mobile-navigation"
      color="primary"
      v-model="drawer"
      fixed
      temporary
    >
      <router-link to="/" class="d-flex px-2 py-4">
        <img
          v-if="informations.img"
          :src="files + informations.img"
          :alt="informations.name"
          class="d-block mx-auto filter-white navbar-brand"
        />
      </router-link>

      <v-divider />

      <v-list v-model="current" class="mt-2 px-0" nav>
        <template v-for="({ text, to, dropdown }, keyMenu) in menu">
          <v-list-item
            v-if="!dropdown"
            class="mb-0 mt-2"
            :key="keyMenu"
            :to="to"
            link
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-body-2 font-weight-bold white--text px-4"
              >
                {{ text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            :key="`${keyMenu}d`"
            :value="false"
            class="dropdown-menu"
            color="secondary"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-title
                class="text-body-2 font-weight-bold white--text px-4"
              >
                {{ text }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="({ text, to }, keyDropdown) in dropdown"
              :key="keyDropdown"
              :to="to"
              class="pl-6"
              link
            >
              <v-list-item-content>
                <v-list-item-title
                  class="text-body-2 font-weight-bold white--text"
                >
                  {{ text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { getSocial } from "../../../utils/getSocial";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: this.$route.path,
      drawer: false,
    };
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-mobile-navigation {
  .navbar-brand {
    width: 90%;
  }
}

.dropdown-menu::v-deep {
  .v-icon {
    font-size: 14px !important;
    color: white !important;
  }

  .v-list-group__header {
    margin-bottom: 0 !important;
    margin-top: 8px;
  }

  .v-list-group__items {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
