<template>
  <v-card
    class="pa-4 pb-2 pa-lg-8 pb-lg-6 rounded-xl item"
    :to="to"
    :title="title"
    height="100%"
    outlined
    flat
  >
    <img
      class="d-block item-img"
      :src="img + '?resize=1&w=300'"
      width="100%"
      :alt="title"
    />

    <v-sheet
      class="d-flex align-center justify-center"
      color="transparent"
      :height="mdAndDown ? '67px' : '84px'"
    >
      <div
        class="text-body-1 text-lg-h6 text-center font-weight-bold item-title"
      >
        {{ title }}
      </div>
    </v-sheet>
  </v-card>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 1 / 1;
    -o-object-fit: contain;
    object-fit: contain;
  }

  &-title {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-height: 1.4 !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
