import { getWhatsappLinkFromPhone } from "./getWhatsappLinkFromPhone";

export function getSocial(component) {
  const { instagram, facebook, youtube, twitter, linkedin, whatsapp } =
    component;
  const social = [];

  if (instagram)
    social.push({
      text: "Instagram",
      link: instagram,
      icon: "fab fa-instagram",
      size: 18,
    });

  if (facebook)
    social.push({
      text: "Facebook",
      link: facebook,
      icon: "fab fa-facebook-f",
      size: 20,
    });

  if (youtube)
    social.push({
      text: "Youtube",
      link: youtube,
      icon: "fab fa-youtube",
      size: 18,
    });

  if (twitter)
    social.push({
      text: "Twitter",
      link: twitter,
      icon: "fab fa-twitter",
      size: 18,
    });

  if (linkedin)
    social.push({
      text: "Linkedin",
      link: linkedin,
      icon: "fab fa-linkedin",
      size: 18,
    });

  if (whatsapp)
    social.push({
      text: "WhatsApp",
      link: getWhatsappLinkFromPhone(whatsapp),
      icon: "fab fa-whatsapp",
      size: 18,
    });

  return social;
}
